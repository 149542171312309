import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { H1, H2, P, Article } from '../components/shared/SystemDesign';
import Anchor from '../components/shared/Anchor';

const PageTemplate = (props) => {

  return (
    <MDXProvider
      components={{
        h1: H1,
        h2: H2,
        p: P,
        a: Anchor,
      }}
    >
      <Article>{props.children}</Article>
    </MDXProvider>
  );
};

export default PageTemplate;