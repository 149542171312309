import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/PageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sobre-o-blog"
    }}>{`Sobre o blog`}</h1>
    <p>{`Blog construído com `}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby.js`}</a>{`, gerador de site estático baseado em `}<a parentName="p" {...{
        "href": "https://reactjs.org/"
      }}>{`React`}</a>{`.`}</p>
    <h2 {...{
      "id": "ferramentas"
    }}>{`Ferramentas`}</h2>
    <p>{`Dentre outras bibliotecas, utilizo:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://emotion.sh/"
        }}>{`Emotion`}</a>{`, biblioteca `}<em parentName="li">{`CSS-in-JSS`}</em>{` particularmente útil para criar componentes `}<strong parentName="li">{`React`}</strong>{` estilizados/tematizados.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mdxjs.com/"
        }}>{`MDX`}</a>{`, biblioteca `}<em parentName="li">{`Markdown`}</em>{` com suporte à componentes `}<strong parentName="li">{`React`}</strong>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://conorhastings.github.io/react-syntax-highlighter"
        }}>{`React Syntax Highlighter`}</a>{`, biblioteca para destaque de sintaxe de código.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://graphql.org/"
        }}>{`GraphQL`}</a>{` para recuperar o conteúdo das páginas `}<strong parentName="li">{`MDX`}</strong>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://react-icons.github.io/react-icons/"
        }}>{`React Icons`}</a>{`, ícones responsivos como componentes `}<strong parentName="li">{`React`}</strong>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://utteranc.es/"
        }}>{`Utterances`}</a>{`, sistema de comentários como `}<em parentName="li">{`issues`}</em>{` no GitHub.`}</li>
    </ul>
    <h2 {...{
      "id": "créditos"
    }}>{`Créditos`}</h2>
    <ul>
      <li parentName="ul">{`Tema `}<strong parentName="li">{`Gatsby.js`}</strong>{` baseado em `}<a parentName="li" {...{
          "href": "https://github.com/greglobinski/gatsby-themes"
        }}>{`Personal Blog`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/odb/official-bash-logo"
        }}>{`Logo do `}<strong parentName="a">{`Bash`}</strong></a>{`. Borda branca acrescida utilizando `}<a parentName="li" {...{
          "href": "https://inkscape.org/"
        }}>{`Inkscape`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.eclipse.org/artwork/zip_file_v2/eclipse_Logov2.zip"
        }}>{`Logo do `}<strong parentName="a">{`Eclipse`}</strong></a>{`. Texto cortado utilizando `}<a parentName="li" {...{
          "href": "https://www.gimp.org/"
        }}>{`GIMP`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      